var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.doctorApproved && _vm.hbApproved)?_c('b-card',{staticClass:"mb-3"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',[_vm._v("Aftap")]),_c('b-form-radio-group',{attrs:{"options":[
        { text: 'Diterima', value: 'Done' },
        { text: 'Ditolak', value: 'Rejected' },
      ]},model:{value:(_vm.form.aftap.status),callback:function ($$v) {_vm.$set(_vm.form.aftap, "status", $$v)},expression:"form.aftap.status"}})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Barcode","label-for":"barcode"}},[_c('b-form-input',{attrs:{"id":"barcode"},model:{value:(_vm.form.aftap.barcode),callback:function ($$v) {_vm.$set(_vm.form.aftap, "barcode", $$v)},expression:"form.aftap.barcode"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor selang","label-for":"blood_bag_number"}},[_c('b-form-input',{attrs:{"id":"blood_bag_number"},model:{value:(_vm.form.aftap.blood_bag_number),callback:function ($$v) {_vm.$set(_vm.form.aftap, "blood_bag_number", $$v)},expression:"form.aftap.blood_bag_number"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Cuci Lengan/Lap Tisu"}},[_c('b-form-radio-group',{attrs:{"options":[
            { text: 'Sudah', value: 1 },
            { text: 'Belum', value: 0 }
          ]},model:{value:(_vm.form.aftap.antiseptic_wipe),callback:function ($$v) {_vm.$set(_vm.form.aftap, "antiseptic_wipe", $$v)},expression:"form.aftap.antiseptic_wipe"}})],1),_c('b-form-group',{attrs:{"label":"Jam Mulai"}},[_c('b-input-group',{staticClass:"mb-3"},[_c('b-form-input',{attrs:{"id":"start-time-input","type":"text","placeholder":"HH:mm"},on:{"input":_vm.onStartTimeInputChange},model:{value:(_vm.form.aftap.venipuncture_start_time),callback:function ($$v) {_vm.$set(_vm.form.aftap, "venipuncture_start_time", $$v)},expression:"form.aftap.venipuncture_start_time"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"show-seconds":false,"button-only":"","right":"","locale":"id","aria-controls":"start-time-input"},on:{"change":_vm.onStartTimepickerChange},model:{value:(_vm.form.aftap.venipuncture_start_time),callback:function ($$v) {_vm.$set(_vm.form.aftap, "venipuncture_start_time", $$v)},expression:"form.aftap.venipuncture_start_time"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Jam Akhir"}},[_c('b-input-group',{staticClass:"mb-3"},[_c('b-form-input',{attrs:{"id":"end-time-input","type":"text","placeholder":"HH:mm"},on:{"input":_vm.onEndTimeInputChange},model:{value:(_vm.form.aftap.venipuncture_end_time),callback:function ($$v) {_vm.$set(_vm.form.aftap, "venipuncture_end_time", $$v)},expression:"form.aftap.venipuncture_end_time"}}),_c('b-input-group-append',[_c('b-form-timepicker',{attrs:{"show-seconds":false,"button-only":"","right":"","locale":"id","aria-controls":"end-time-input"},on:{"change":_vm.onEndTimepickerChange},model:{value:(_vm.form.aftap.venipuncture_end_time),callback:function ($$v) {_vm.$set(_vm.form.aftap, "venipuncture_end_time", $$v)},expression:"form.aftap.venipuncture_end_time"}})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Reaksi Pengambilan","label-for":"uptake_reaction"}},[_c('b-form-select',{attrs:{"id":"uptake_reaction","options":[
            { value: '', text: 'Pilih Reaksi Pengambilan' },
            { value: 'Lancar', text: 'Lancar' },
            { value: 'Tidak Lancar', text: 'Tidak Lancar' },
            { value: 'Stop', text: 'Stop' },
          ]},model:{value:(_vm.form.aftap.uptake_reaction),callback:function ($$v) {_vm.$set(_vm.form.aftap, "uptake_reaction", $$v)},expression:"form.aftap.uptake_reaction"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Detail Reaksi Pengambilan","label-for":"uptake_reaction_detail"}},[_c('b-form-input',{attrs:{"id":"uptake_reaction_detail","placeholder":"Masukkan detail reaksi pengambilan","disabled":_vm.form.aftap.uptake_reaction !== 'Stop'},model:{value:(_vm.form.aftap.uptake_reaction_detail),callback:function ($$v) {_vm.$set(_vm.form.aftap, "uptake_reaction_detail", $$v)},expression:"form.aftap.uptake_reaction_detail"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Reaksi Samping","label-for":"side_reaction"}},[_c('b-form-select',{attrs:{"id":"side_reaction","options":[
            { value: '', text: 'Pilih Reaksi Samping' },
            { value: 'Tidak Ada', text: 'Tidak Ada' },
            { value: 'Hematoma', text: 'Hematoma' },
            { value: 'Pusing', text: 'Pusing' },
            { value: 'Muntah', text: 'Muntah' },
            { value: 'Lainnya', text: 'Lainnya' },
          ]},model:{value:(_vm.form.aftap.side_reaction),callback:function ($$v) {_vm.$set(_vm.form.aftap, "side_reaction", $$v)},expression:"form.aftap.side_reaction"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Detail Reaksi Samping","label-for":"side_reaction_detail"}},[_c('b-form-input',{attrs:{"id":"side_reaction_detail","placeholder":"Masukkan detail reaksi samping","disabled":_vm.form.aftap.side_reaction !== 'Lainnya'},model:{value:(_vm.form.aftap.side_reaction_detail),callback:function ($$v) {_vm.$set(_vm.form.aftap, "side_reaction_detail", $$v)},expression:"form.aftap.side_reaction_detail"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"h5 text-primary hp-hover-bg-primary-4 hp-hover-bg-dark-primary",staticStyle:{"cursor":"pointer"},attrs:{"variant":"text"},on:{"click":_vm.emitClearForm}},[_vm._v("Reset")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }