<template>
  <b-card
    title="Pendonor"
    class="mb-3"
  >
    <b-row>
      <!-- NIK and Full Name -->
      <b-col cols="6">
        <b-form-group
          label="NIK"
          label-for="nik"
        >
          <b-form-input
            id="nik"
            v-model="form.donation.nik"
            :state="getValidationState('nik')"
            @input="handleInput"
            @blur="v$.form.donation.nik.$touch()"
          />
          <b-form-invalid-feedback :state="getValidationState('nik')">
            {{ getErrorMessage('nik') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Nama Lengkap"
          label-for="full_name"
        >
          <b-form-input
            id="full_name"
            v-model="form.donation.full_name"
            :state="getValidationState('full_name')"
            @input="handleInput"
            @blur="v$.form.donation.full_name.$touch()"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="getValidationState('full_name')">
          {{ getErrorMessage('full_name') }}
        </b-form-invalid-feedback>
      </b-col>
    </b-row>

    <b-row>
      <!-- Email and sex -->
      <b-col cols="6">
        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="form.donation.email"
            type="email"
            :state="getValidationState('email')"
            @input="handleInput"
            @blur="v$.form.donation.email.$touch()"
          />
          <b-form-invalid-feedback :state="getValidationState('full_name')">
            {{ getErrorMessage('email') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Jenis Kelamin"
          label-for="sex"
        >
          <b-form-select
            id="sex"
            v-model="form.donation.sex"
            :options="[
              { value: 'male', text: 'Laki-laki' },
              { value: 'female', text: 'Perempuan' },
            ]"
            placeholder="Pilih Jenis Kelamin"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Date of Birth and Place of Birth -->
      <b-col cols="6">
        <b-form-group
          label="Tanggal Lahir"
          label-for="date_of_birth"
        >
          <b-form-input
            id="date_of_birth"
            v-model="form.donation.birth_date"
            type="date"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Tempat Lahir"
          label-for="place_of_birth"
        >
          <b-form-input
            id="place_of_birth"
            v-model="form.donation.birth_place"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Address -->
      <b-col cols="12">
        <b-form-group
          label="Alamat"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="form.donation.address"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Province and City District -->
      <b-col cols="6">
        <b-form-group
          label="Provinsi"
          label-for="province"
        >
          <b-form-input
            id="province"
            v-model="form.donation.province"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Kota/Kabupaten"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="form.donation.city"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- district and Village -->
      <b-col cols="6">
        <b-form-group
          label="Kecamatan"
          label-for="district"
        >
          <b-form-input
            id="district"
            v-model="form.donation.district"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Desa/Kelurahan"
          label-for="village"
        >
          <b-form-input
            id="village"
            v-model="form.donation.village"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Phone Number and Office Phone Number -->
      <b-col cols="6">
        <b-form-group
          label="Nomor Telepon"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="form.donation.phone"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Nomor Telepon Kantor"
          label-for="phone_office"
        >
          <b-form-input
            id="phone_office"
            v-model="form.donation.phone_office"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- job -->
      <b-col cols="6">
        <b-form-group
          label="Pekerjaan"
          label-for="job"
        >
          <b-form-select
            id="job"
            v-model="form.donation.job"
            :options="[
              { value: '', text: 'Pilih Pekerjaan' },
              { value: 'TNI / Polri', text: 'TNI / Polri' },
              {
                value: 'Pegawai Negeri / Swasta',
                text: 'Pegawai Negeri / Swasta',
              },
              { value: 'Petani Buruh', text: 'Petani Buruh' },
              { value: 'Wiraswasta', text: 'Wiraswasta' },
              { value: 'Mahasiswa', text: 'Mahasiswa' },
              { value: 'Pedagang', text: 'Pedagang' },
              { value: 'Lainnya', text: 'Lainnya' },
            ]"
            placeholder="Pilih Pekerjaan"
          />
        </b-form-group>
      </b-col>

      <!-- Number of Awards -->
      <b-col cols="6">
        <b-form-group
          label="Jumlah Penghargaan"
          label-for="award"
        >
          <b-form-select
            id="award"
            v-model="form.donation.award"
            :options="[
              { value: '', text: 'Pilih Jumlah Penghargaan' },
              { value: 0, text: 'Belum Ada' },
              { value: 10, text: '10x' },
              { value: 25, text: '20x' },
              { value: 50, text: '50x' },
              { value: 100, text: '100x' },
            ]"
            placeholder="Pilih Jumlah Penghargaan"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Password"
          label-for="password"
          type="password"
        >
          <b-form-input
            id="password"
            v-model="form.donation.password"
            type="password"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Konfirmasi Password"
          label-for="c_password"
        >
          <b-form-input
            id="c_password"
            v-model="form.donation.c_password"
            type="password"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <!-- Willing to Donate During Fasting -->
      <b-col cols="6">
        <b-form-group label="Bersedia Donor Saat Puasa">
          <b-form-checkbox v-model="form.donation.ready_fasting" :true-value="1" :false-value="0">
            Ya
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Willing to Donate When Needed -->
      <b-col cols="6">
        <b-form-group label="Bersedia Donor Saat Diperlukan">
          <b-form-checkbox v-model="form.donation.ready_donor" :true-value="1" :false-value="0">
            Ya
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BRow, BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  name: 'FormReservation',
  components: {
    BFormGroup, BRow, BCol, BCard, BFormCheckbox, BFormInput, BFormSelect, BFormInvalidFeedback,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    v$: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDirty: false,
      showValidation: false,
    }
  },
  watch: {
    localForm: {
      deep: true,
      handler(newValue) {
        this.$emit('update:form', newValue)
      },
    },
  },
  methods: {
    getValidationState(fieldName) {
      const field = this.v$.form.donation[fieldName]
      if (!field.$dirty) return null
      return !field.$error
    },

    getErrorMessage(fieldName) {
      const field = this.v$.form.donation[fieldName]

      if (!field.$dirty) return ''

      // Pesan error sesuai jenis validasi
      if (field.required?.$invalid) {
        return `${fieldName.toUpperCase()} harus diisi`
      }

      if (field.minLength?.$invalid) {
        return `${fieldName.toUpperCase()} harus setidaknya 16 karakter`
      }

      if (field.email?.$invalid) {
        return 'Format email tidak valid'
      }

      return ''
    },
    handleInput() {
      this.$emit('update:form', this.form)
    },
  },

}
</script>
