<template>
  <b-card
    class="mb-3"
  >
    <div class="d-flex justify-content-between">
      <h2>Reservasi</h2>
      <b-form-radio-group
        v-model="form.reservation.status"
        :options="[
          { text: 'Diterima', value: 'Done' },
          { text: 'Ditolak', value: 'Rejected' },
        ]"
      />
    </div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Mobil Unit *"
          label-for="blood_van"
        >
          <registered-m-u-lists
            v-model="localSelectedMU"
            @input="handleInputMU"
          />
        </b-form-group>
        <b-form-group
          label="No.KTP/SIM/Paspor *"
          label-for="validation"
        >
          <b-form-select
            id="validation"
            v-model="form.reservation.validation"
            :options="[
              { text: 'Pilih Jenis Identitas', value: '' },
              { text: 'KTP', value: 'KTP' },
              { text: 'Kartu Donor', value: 'Kartu Donor' },
              { text: 'SIM', value: 'SIM' },
              { text: 'Paspor', value: 'Paspor' },
            ]"
            placeholder="Pilih Jenis Identitas"
          />
        </b-form-group>
        <b-form-group
          label="Macam Donor *"
          label-for="donor_type"
        >
          <b-form-select
            id="donor_type"
            v-model="form.reservation.donor_type"
            :options="[
              { text: 'Pilih Macam Donor', value: null },
              { text: 'Sukarela', value: 'Sukarela' },
              { text: 'Pengganti', value: 'Pengganti' },
            ]"
            placeholder="Pilih Macam Donor"
          />
        </b-form-group>
        <b-form-group
          label="Donor Terakhir Tanggal"
          label-for="last_donation_date"
        >
          <b-form-input
            id="last_donation_date"
            v-model="form.reservation.last_donor_date"
            type="date"
          />
        </b-form-group>
        <b-form-group
          label="Sekarang donor yang ke *"
          label-for="current_donation_count"
        >
          <b-form-input
            id="current_donation_count"
            v-model="form.reservation.donor_times"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <div class="d-flex" style="gap: 0.5rem">
          <b-form-group
            style="flex: 1"
            label="Tanggal *"
            label-for="date"
          >
            <b-form-input
              id="date"
              v-model="form.reservation.date"
              type="date"
            />
          </b-form-group>
          <b-form-group
            style="flex: 1"
            label="Jam *"
            label-for="time"
          >
            <b-form-input
              id="time"
              v-model="form.reservation.hour"
              type="time"
            />
          </b-form-group>
        </div>
        <b-form-group
          label="Riwayat Donor Sebelumnya"
          label-for="history_donor"
        >
          <b-form-textarea
            id="history_donor"
            v-model="form.reservation.prev_history"
            rows="3"
            type="text"
          />
        </b-form-group>
        <b-form-group
          label="OS "
          label-for="os"
        >
          <b-form-input
            id="os"
            v-model="form.reservation.os"
            type="text"
            :disabled="isOsDisabled"
          />
        </b-form-group>
        <b-form-group
          label="RS "
          label-for="rs"
        >
          <b-form-input
            id="rs"
            v-model="form.reservation.rs"
            type="text"
            :disabled="isOsDisabled"
          />
        </b-form-group>
        <b-form-group
          label="Alasan Penolakan "
          label-for="rejected"
        >
          <b-form-input
            id="rejected"
            v-model="form.reservation.rejectedReason"
            type="text"
          />
          <span class="hp-p1-label">Isi jika terdapat Penolakan</span>
        </b-form-group>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="cursor: pointer;"
          variant="text"
          class="h5 text-primary hp-hover-bg-primary-4 hp-hover-bg-dark-primary"
          @click="emitClearForm"
        >Reset</b-button>
      </b-col>
    </b-row>
  </b-card>

</template>
<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BRow,
  BFormRadioGroup,
} from 'bootstrap-vue'
import RegisteredMULists from '../../../../../components/shared/RegisteredMULists.vue'

export default {
  name: 'FormAdmin',
  components: {
    BFormGroup, BRow, BCol, RegisteredMULists, BCard, BFormTextarea, BFormInput, BFormSelect, BFormRadioGroup,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    formType: {
      type: String,
      required: true,
    },
    isOsDisabled: {
      type: Boolean,
      required: true,
    },
    selectedMu: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      localSelectedMU: this.form.reservation.blood_van,
    }
  },
  methods: {
    emitClearForm() {
      this.$emit('clear-form', this.formType)
    },
    handleInputMU(value) {
      this.$emit('choose-mu', value)
    },
  },
}
</script>
