<template>
  <b-card
    v-if="doctorApproved && hbApproved"
    class="mb-3"
  >
    <div class="d-flex justify-content-between">
      <h2>Aftap</h2>
      <b-form-radio-group
        v-model="form.aftap.status"
        :options="[
          { text: 'Diterima', value: 'Done' },
          { text: 'Ditolak', value: 'Rejected' },
        ]"
      />
    </div>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Barcode"
          label-for="barcode"
        >
          <b-form-input
            id="barcode"
            v-model="form.aftap.barcode"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Nomor selang"
          label-for="blood_bag_number"
        >
          <b-form-input
            id="blood_bag_number"
            v-model="form.aftap.blood_bag_number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Cuci Lengan/Lap Tisu">
          <b-form-radio-group
            v-model="form.aftap.antiseptic_wipe"
            :options="[
              { text: 'Sudah', value: 1 },
              { text: 'Belum', value: 0 }
            ]"
          />
        </b-form-group>
        <b-form-group label="Jam Mulai">
          <b-input-group class="mb-3">
            <b-form-input
              id="start-time-input"
              v-model="form.aftap.venipuncture_start_time"
              type="text"
              placeholder="HH:mm"
              @input="onStartTimeInputChange"
            />
            <b-input-group-append>
              <b-form-timepicker
                v-model="form.aftap.venipuncture_start_time"
                :show-seconds="false"
                button-only
                right
                locale="id"
                aria-controls="start-time-input"
                @change="onStartTimepickerChange"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Jam Akhir">
          <b-input-group class="mb-3">
            <b-form-input
              id="end-time-input"
              v-model="form.aftap.venipuncture_end_time"
              type="text"
              placeholder="HH:mm"
              @input="onEndTimeInputChange"
            />
            <b-input-group-append>
              <b-form-timepicker
                v-model="form.aftap.venipuncture_end_time"
                :show-seconds="false"
                button-only
                right
                locale="id"
                aria-controls="end-time-input"
                @change="onEndTimepickerChange"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Reaksi Pengambilan"
          label-for="uptake_reaction"
        >
          <b-form-select
            id="uptake_reaction"
            v-model="form.aftap.uptake_reaction"
            :options="[
              { value: '', text: 'Pilih Reaksi Pengambilan' },
              { value: 'Lancar', text: 'Lancar' },
              { value: 'Tidak Lancar', text: 'Tidak Lancar' },
              { value: 'Stop', text: 'Stop' },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Detail Reaksi Pengambilan"
          label-for="uptake_reaction_detail"
        >
          <b-form-input
            id="uptake_reaction_detail"
            v-model="form.aftap.uptake_reaction_detail"
            placeholder="Masukkan detail reaksi pengambilan"
            :disabled="form.aftap.uptake_reaction !== 'Stop'"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Reaksi Samping"
          label-for="side_reaction"
        >
          <b-form-select
            id="side_reaction"
            v-model="form.aftap.side_reaction"
            :options="[
              { value: '', text: 'Pilih Reaksi Samping' },
              { value: 'Tidak Ada', text: 'Tidak Ada' },
              { value: 'Hematoma', text: 'Hematoma' },
              { value: 'Pusing', text: 'Pusing' },
              { value: 'Muntah', text: 'Muntah' },
              { value: 'Lainnya', text: 'Lainnya' },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Detail Reaksi Samping"
          label-for="side_reaction_detail"
        >
          <b-form-input
            id="side_reaction_detail"
            v-model="form.aftap.side_reaction_detail"
            placeholder="Masukkan detail reaksi samping"
            :disabled="form.aftap.side_reaction !== 'Lainnya'"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="cursor: pointer;"
          variant="text"
          class="h5 text-primary hp-hover-bg-primary-4 hp-hover-bg-dark-primary"
          @click="emitClearForm"
        >Reset</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormRadioGroup,
  BCard, BCol, BFormGroup, BFormInput, BFormSelect, BRow, BInputGroupAppend, BFormTimepicker, BFormRadio,
} from 'bootstrap-vue'

export default {
  name: 'FormAftap',
  components: {
    BCard, BRow, BFormInput, BFormSelect, BFormGroup, BCol, BFormRadioGroup, BFormTimepicker, BFormRadio, BInputGroupAppend,
  },
  props: ['form', 'formType', 'doctorApproved', 'hbApproved'],
  data() {
    return {
      startTimeInput: '',
      endTimeInput: '',
    }
  },
  watch: {
    'form.startTime': {
      handler(newVal) {
        this.form.aftap.venipuncture_start_time = this.formatTime(newVal)
        this.startTimeInput = this.form.aftap.venipuncture_start_time
      },
      immediate: true,
    },
    'form.endTime': {
      handler(newVal) {
        this.form.aftap.venipuncture_end_time = this.formatTime(newVal)
        this.endTimeInput = this.form.aftap.venipuncture_end_time
      },
      immediate: true,
    },
  },
  methods: {
    emitClearForm() {
      this.$emit('clear-form', this.formType)
    },
    onStartTimeInputChange(value) {
      this.form.aftap.venipuncture_start_time = value
    },
    onStartTimepickerChange(value) {
      this.form.aftap.venipuncture_start_time = value
      this.startTimeInput = value
    },
    onEndTimeInputChange(value) {
      this.form.aftap.venipuncture_end_time = value
    },
    onEndTimepickerChange(value) {
      this.form.aftap.venipuncture_end_time = value
      this.endTimeInput = value
    },
    formatTime(value) {
      if (value) {
        return value.split(':').slice(0, 2).join(':')
      }
      return ''
    },
  },
}
</script>

<style scoped>

</style>
