<template>
  <b-card
    title="Kuesioner"
    class="mb-3"
  >
    <b-table
      striped
      hover
      responsive
      :items="form.questionnaire"
      :fields="questionnaireFields"
    >
      <template #cell(answer)="row">
        <b-row style="width: max-content; margin-right: 5px">
          <b-form-checkbox
            v-model="row.item.answer"
            class="mr-3"
            :checked="row.item.answer === 'Ya'"
            value="Ya"
            name="answer"
          >
            Ya
          </b-form-checkbox>
          <b-form-checkbox
            v-model="row.item.answer"
            :checked="row.item.answer === 'Tidak'"
            value="Tidak"
            name="answer"
          >
            Tidak
          </b-form-checkbox>
        </b-row>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BFormCheckbox, BRow, BTable,
} from 'bootstrap-vue'

export default {
  name: 'FormQuestionnaire',
  components: {
    BRow, BCard, BFormCheckbox, BTable,
  },
  props: {
    form: {
      type: Object,
      default: () => [],
    },
    questionnaireFields: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>

</style>
