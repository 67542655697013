<template>
  <b-card
    v-if="doctorApproved"
    class="mb-3"
  >
    <div class="d-flex justify-content-between">
      <h2>HB</h2>
      <b-form-radio-group
        v-model="form.hb.status"
        :options="[
          { text: 'Diterima', value: 'Done' },
          { text: 'Ditolak', value: 'Rejected' },
        ]"
      />
    </div>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="HB Meter (g/dL)"
          label-for="hb_meter"
        >
          <b-form-input
            id="hb_meter"
            v-model="form.hb.hb_meter"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Alasan Penolakan HB"
          label-for="hb_reason_rejected"
        >
          <b-form-input
            id="hb_reason_rejected"
            :value="hbRejectionReason"
            :state="hbApproved ? null : false"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group label="Golongan Darah">
          <b-form-select
            v-model="form.hb.blood_type"
            :disabled="hbRejectionReason !== ''"
          >
            <b-form-select-option
              v-for="bloodType in bloodTypes"
              :key="bloodType.id"
              :value="bloodType.id"
            >
              {{ bloodType.type || bloodType.text }}
            </b-form-select-option>
            <b-form-select-option
              v-if="hbRejectionReason !== ''"
              disabled
            >
              {{ hbRejectionReason !== '' ? form.hb.blood_type = '-' : null }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col class="d-flex justify-content-end">
        <b-button
          style="cursor: pointer;"
          variant="text"
          class="h5 text-primary hp-hover-bg-primary-4 hp-hover-bg-dark-primary"
          @click="emitClearForm"
        >Reset</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormRadioGroup, BFormInput, BFormSelect, BFormSelectOption, BRow,
} from 'bootstrap-vue'

export default {
  name: 'FormHB',
  components: {
    BCard, BRow, BFormInput, BFormRadioGroup, BFormSelectOption, BFormSelect, BFormGroup, BCol,
  },
  props: ['form', 'formType', 'hbRejectionReason', 'hbApproved', 'doctorApproved', 'bloodTypes'],
  methods: {
    emitClearForm() {
      this.$emit('clear-form', this.formType)
    },
  },
}
</script>

<style scoped>

</style>
