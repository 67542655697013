import API from "../services/services";

class ManageExamsService {
    getAll(params) {
        return API.get('examination/list', { params })
    }

    add(payload) {
        return API.post('examination/list/store', payload)
    }

    edit(payload) {
        return API.post('examination/list/update', payload)
    }
}

export default new ManageExamsService()