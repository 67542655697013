<template>
  <b-container class="bg-white p-3 rounded shadow-sm">
    <h1>Formulir Donor Darah</h1>

    <!-- Section: Donation Details -->
    <form-reservation
      :form="form"
      :v$="v$"
      @update:form="updateForm"
    />

    <!-- section questionnaire -->
    <form-questionnaire
      :form="form"
      :questionnaire-fields="questionnaireFields"
    />

    <!-- Section: Select Mobil Unit and Date -->
    <form-admin
      :form="form"
      form-type="admin"
      :selected-mu="selectedMU"
      :is-os-disabled="isOSRSDisabled"
      @clear-form="clearForm"
      @choose-mu="handleInputMU"
    />

    <!-- Section: Doctor -->
    <form-dokter
      :form="form"
      form-type="dokter"
      :doctor-approved="doctorApproved"
      :doctor-rejection-reason="doctorRejectionReason"
      @clear-form="clearForm"
    />

    <!-- Section: HB (disable if rejected by doctor) -->
    <FormHB
      :form="form"
      form-type="hb"
      :hb-rejection-reason="hbRejectionReason"
      :hb-approved="hbApproved"
      :doctor-approved="doctorApproved"
      :blood-types="bloodTypes"
      @clear-form="clearForm"
    />

    <!-- Section: Aftap (disable if rejected by doctor or HB) -->
    <form-aftap
      :form="form"
      form-type="aftap"
      :doctor-approved="doctorApproved"
      :hb-approved="hbApproved"
      @clear-form="clearForm"
    />

    <!-- Submit Button -->
    <div class="d-flex justify-content-end">
      <base-button
        variant="primary"
        class="mt-3"
        text="Simpan"
        @click="submitForm"
      />
    </div>
  </b-container>
</template>

<script>
import {
  BCard,
  BContainer,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BRow,
  BCol,
  BFormCheckbox,
  BTable,
  BFormTextarea,
} from 'bootstrap-vue'
import { useVuelidate } from '@vuelidate/core'
import {
  minLength, required, sameAs, email,
} from '@vuelidate/validators'
import RegisteredMULists from '../../../../components/shared/RegisteredMULists.vue'

// reusable component import
import FormReservation from './components/FormReservation.vue'
import FormQuestionnaire from './components/FormQuestionnaire.vue'
import FormAdmin from './components/FormAdmin.vue'
import FormDokter from './components/FormDokter.vue'
import FormHB from './components/FormHB.vue'
import FormAftap from './components/FormAftap.vue'

// service import
import manageExams from '../../../../api/examinations/manageExams'

export default {
  setup() { return { v$: useVuelidate() } },
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BContainer,
    BFormSelect,
    BRow,
    BCol,
    BFormCheckbox,
    BFormSelectOption,
    BTable,
    BFormTextarea,
    RegisteredMULists,
    FormReservation,
    FormQuestionnaire,
    FormAdmin,
    FormDokter,
    FormHB,
    FormAftap,
  },
  data() {
    return {
      form: {
        reservation: {
          blood_van_id: null,
          hospital_id: null,
          date: '',
          hour: '',
          validation: '',
          prev_history: '-',
          donor_times: 1,
          donor_type: '-',
          last_donor_date: '',
          os: '-',
          rs: '-',
          status: '',
          rejectedReason: '',
        },
        questionnaire: [
          {
            id: 1,
            question: 'Apakah anda merasa sehat pada hari ini?',
            answer: 'Ya',
          },
          {
            id: 2,
            question: 'Apakah anda minum antibiotik?',
            answer: 'Tidak',
          },
          {
            id: 3,
            question: 'Apakah anda minum obat lain untuk infeksi?',
            answer: 'Tidak',
          },
          {
            id: 4,
            question:
              'Apakah anda sedang minum Aspirin atau obat yang mengandung asam asetil salisilat?',
            answer: 'Tidak',
          },
          {
            id: 5,
            question:
              'Dalam waktu 1 minggu terakhir, apakah anda mengalami sakit kepala dan demam?',
            answer: 'Tidak',
          },
          {
            id: 6,
            question: 'Untuk donor wanita: apakah anda sedang hamil?',
            answer: 'Tidak',
          },
          {
            id: 7,
            question: 'Apakah anda mendonorkan darah, trombosit atau plasma?',
            answer: 'Tidak',
          },
          {
            id: 8,
            question: 'Apakah anda menerima vaksinasi atau suntikan lainnya?',
            answer: 'Tidak',
          },
          {
            id: 9,
            question:
              'Apakah anda pernah kontak dengan orang yang menerima vaksinasi smallpox?',
            answer: 'Tidak',
          },
          {
            id: 10,
            question:
              'Apakah anda mendonorkan 2 kantong sel darah merah melalui proses aferesis?',
            answer: 'Tidak',
          },
          {
            id: 11,
            question: 'Apakah anda pernah menerima transfusi darah?',
            answer: 'Tidak',
          },
          {
            id: 12,
            question:
              'Apakah anda pernah mendapat transplantasi, organ, jaringan atau sumsum tulang?',
            answer: 'Tidak',
          },
          {
            id: 13,
            question: 'Apakah anda pernah cangkok tulang atau kulit?',
            answer: 'Tidak',
          },
          {
            id: 14,
            question: 'Apakah anda pernah tertusuk jarum medis?',
            answer: 'Tidak',
          },
          {
            id: 15,
            question: 'Apakah anda pernah berhubungan seksual dengan ODHA?',
            answer: 'Tidak',
          },
          {
            id: 16,
            question: 'Apakah anda pernah berhubungan seksual dengan WPS?',
            answer: 'Tidak',
          },
          {
            id: 17,
            question:
              'Apakah anda pernah berhubungan seksual dengan pengguna narkoba jarum suntik?',
            answer: 'Tidak',
          },
          {
            id: 18,
            question:
              'Apakah anda pernah berhubungan seksual dengan pengguna konsentrat faktor pembekuan?',
            answer: 'Tidak',
          },
          {
            id: 19,
            question:
              'Donor wanita: apakah anda pernah berhubungan seksual dengan laki-laki yang biseksual?',
            answer: 'Tidak',
          },
          {
            id: 20,
            question:
              'Apakah anda pernah berhubungan seksual dengan penderita hepatitis?',
            answer: 'Tidak',
          },
          {
            id: 21,
            question: 'Apakah anda tinggal bersama penderita hepatitis?',
            answer: 'Tidak',
          },
          {
            id: 22,
            question: 'Apakah anda memiliki tato?',
            answer: 'Tidak',
          },
          {
            id: 23,
            question:
              'Apakah anda memiliki / melakukan tindik telinga atau bagian tubuh lainnya?',
            answer: 'Tidak',
          },
          {
            id: 24,
            question:
              'Apakah anda sedang atau pernah mendapat pengobatan sifilis atau GO (kencing nanah)?',
            answer: 'Tidak',
          },
          {
            id: 25,
            question:
              'Apakah anda pernah ditahan di penjara untuk waktu lebih dari 72 jam?',
            answer: 'Tidak',
          },
          {
            id: 26,
            question: 'Apakah anda pernah berada di luar wilayah Indonesia?',
            answer: 'Tidak',
          },
          {
            id: 27,
            question:
              'Apakah anda tinggal selama 3 bulan atau lebih di Inggris?',
            answer: 'Tidak',
          },
          {
            id: 28,
            question: 'Apakah anda tinggal selama 5 tahun atau lebih di Eropa?',
            answer: 'Tidak',
          },
          {
            id: 29,
            question: 'Apakah anda menerima transfusi darah di Inggris?',
            answer: 'Tidak',
          },
          {
            id: 30,
            question:
              'Apakah anda menerima uang, obat atau pembayaran lainnya untuk seks?',
            answer: 'Tidak',
          },
          {
            id: 31,
            question:
              'Laki-laki: apakah anda pernah berhubungan seksual dengan laki-laki, walaupun sekali?',
            answer: 'Tidak',
          },
          {
            id: 32,
            question:
              'Apakah anda pernah mendapatkan hasil positif untuk tes HIV/AIDS?',
            answer: 'Tidak',
          },
          {
            id: 33,
            question:
              'Apakah anda pernah menggunakan jarum suntik untuk obat-obatan, steroid yang tidak diresepkan dokter?',
            answer: 'Tidak',
          },
          {
            id: 34,
            question:
              'Apakah anda pernah menggunakan konsentrat faktor pembekuan?',
            answer: 'Tidak',
          },
          {
            id: 35,
            question: 'Apakah anda pernah menderita Hepatitis?',
            answer: 'Tidak',
          },
          {
            id: 36,
            question: 'Apakah anda pernah menderita Malaria?',
            answer: 'Tidak',
          },
          {
            id: 37,
            question: 'Apakah anda pernah menderita kanker termasuk leukimia?',
            answer: 'Tidak',
          },
          {
            id: 38,
            question:
              'Apakah anda pernah bermasalah dengan jantung dan paru-paru?',
            answer: 'Tidak',
          },
          {
            id: 39,
            question:
              'Apakah anda pernah menderita pendarahan atau penyakit berhubungan dengan darah?',
            answer: 'Tidak',
          },
          {
            id: 40,
            question:
              'Apakah anda pernah berhubungan seksual dengan orang yang tinggal di Afrika?',
            answer: 'Tidak',
          },
          {
            id: 41,
            question: 'Apakah anda pernah tinggal di Afrika?',
            answer: 'Tidak',
          },
        ],
        donation: {
          nik: '1234567890123456',
          full_name: 'John Doe',
          email: 'johndoe@example.com',
          sex: 'Laki-laki',
          address: '123 Example Street, Jakarta',
          birth_date: '1990-01-01',
          birth_place: 'Jakarta',
          province: 'DKI Jakarta',
          city: 'South Jakarta',
          district: 'Kebayoran Baru',
          village: 'Gandaria',
          phone: '+6281234567890',
          address_office: '456 Office Street, Jakarta',
          phone_office: '+6280987654321',
          job: '',
          award: '',
          last_donor_date: '2022-01-01',
          ready_fasting: 0,
          ready_donor: 0,
          password: '',
          c_password: '',
        },
        doctor: {
          blood_pressure: '120/80',
          weight: 70.5,
          height: 175,
          general_condition: 'Sehat',
          pulse: '72',
          temperature: 36.6,
          medical_history: 'No relevant medical history',
          collection_method: 'Biasa',
          bag: 'DB',
          cc: '',
          status: '',
          reason_rejected: '',
        },
        hb: {
          hb_meter: 13.5,
          blood_id: 0,
          reason_rejected: '',
          status: 'Approved',
        },
        aftap: {
          barcode: 'BAG123456',
          blood_bag_number: 'TUBE789012',
          antiseptic_wipe: 'Done',
          venipuncture_start_time: '08:00:00',
          venipuncture_end_time: '08:15:00',
          collection_reaction: 'None',
          uptake_reaction: 'Lancar',
          uptake_reaction_detail: '',
          side_effect: 'Tidak Ada',
          side_reaction_detail: '',
          reason_rejected: '',
          status: '',
        },
        step_details: {
          step: 0,
          is_rejected: 0,
        },
      },
      questionnaireFields: [
        { key: 'id', label: 'No' },
        { key: 'question', label: 'Question' },
        { key: 'answer', label: 'Answer' },
      ],
      bloodTypes: [
        { id: null, type: null, text: 'Pilih Golongan Darah' },
        { id: null, type: '-' },
        { id: 1, type: 'A Pos' },
        { id: 2, type: 'A Neg' },
        { id: 3, type: 'B Pos' },
        { id: 4, type: 'B Neg' },
        { id: 5, type: 'AB Pos' },
        { id: 6, type: 'AB Neg' },
        { id: 7, type: 'O Pos' },
        { id: 8, type: 'O Neg' },
      ],
      selectedMU: null,
    }
  },
  validations() {
    return {
      form: {
        donation: {
          nik: {
            minLength: minLength(16),
            required,
          },
          full_name: { required },
          email: { email, required },
        },
      },
    }
  },
  computed: {
    doctorRejectionReason() {
      const { blood_pressure, weight } = this.form.doctor
      const [systolic, diastolic] = blood_pressure.split('/').map(Number)

      if (
        systolic >= 100
        && systolic <= 160
        && diastolic >= 60
        && diastolic <= 100
      ) {
        return '' // No rejection reason
      } if (systolic > 160 || diastolic > 100) {
        return 'Tensi tinggi'
      } if (systolic < 100 || diastolic < 60) {
        return 'Tensi rendah'
      } if (weight < 45) {
        return 'Berat badan kurang'
      }
      return '' // No rejection reason
    },
    doctorApproved() {
      // The doctor form is approved if no rejection reason is present
      return !this.doctorRejectionReason
    },
    hbRejectionReason() {
      const { hb_meter } = this.form.hb

      if (hb_meter < 12) {
        return 'HB rendah'
      } if (hb_meter > 18) {
        return 'HB tinggi'
      }
      return '' // No rejection reason
    },
    hbApproved() {
      // The HB form is approved if no rejection reason is present
      return !this.hbRejectionReason
    },
    isOSRSDisabled() {
      return this.form.reservation.donor_type === 'Sukarela'
    },
  },
  watch: {
    'form.hb.status': 'handleStatusChange',
    'form.aftap.status': 'handleStatusChange',
    'form.reservation.status': 'handleStatusChange',
    'form.doctor.status': 'handleStatusChange',
  },
  methods: {
    async submitForm() {
      const payload = {
        donation: {
          name: this.form.donation.full_name,
          email: this.form.donation.email,
          password: this.form.donation.password,
          c_password: this.form.donation.c_password,
          birth_date: this.form.donation.birth_date,
          sex: this.form.donation.sex,
          address: this.form.donation.address,
          phone: this.form.donation.phone,
          nik: this.form.donation.nik,
          address_office: this.form.donation.address_office,
          phone_office: this.form.donation.phone_office,
          no_card: '',
          job: this.form.donation.job,
          birth_place: this.form.donation.birth_place,
          village: this.form.donation.village,
          district: this.form.donation.district,
          city: this.form.donation.city,
          province: this.form.donation.province,
          ready_fasting: this.form.donation.ready_fasting,
          ready_donor: this.form.donation.ready_donor,
          award: this.form.donation.award,
        },
        reservation: {
          date: this.form.reservation.date,
          hour: this.form.reservation.hour,
          validation: this.form.reservation.validation,
          prev_history: this.form.reservation.prev_history,
          donor_times: this.form.reservation.donor_times,
          donor_type: this.form.reservation.donor_type,
          last_donor_date: this.form.reservation.last_donor_date,
          os: this.form.reservation.os,
          rs: this.form.reservation.rs,
          status: this.form.reservation.status,
        },
        data_answer: this.form.questionnaire,
        registration_step: {
          step: this.form.step_details.step,
          is_rejected: this.form.step_details.is_rejected,
        },
      }

      const getValidPayload = () => {
        const finalPayload = { ...payload }

        if (this.form.reservation.status === 'Rejected') {
          return finalPayload
        }

        if (this.form.doctor.status === 'Rejected') {
          finalPayload.doctor = {
            blood_pressure: this.form.doctor.blood_pressure,
            weight: this.form.doctor.weight,
            height: this.form.doctor.height,
            general_condition: this.form.doctor.general_condition,
            pulse: this.form.doctor.pulse,
            temperature: this.form.doctor.temperature,
            medical_history: this.form.doctor.medical_history,
            collection_method: this.form.doctor.collection_method,
            bag: this.form.doctor.bag,
            cc: this.form.doctor.cc,
            status: this.form.doctor.status,
            reason_rejected: this.form.doctor.reason_rejected,
          }

          return finalPayload
        }

        finalPayload.doctor = { ...this.form.doctor }

        if (this.form.hb.status === 'Rejected') {
          finalPayload.hb = {
            hb_meter: this.form.hb.hb_meter,
            blood_id: this.form.hb.blood_id,
            status: this.form.hb.status,
            reason_rejected: this.form.hb.reason_rejected,
          }

          return finalPayload
        }

        finalPayload.hb = { ...this.form.hb }

        if (this.form.aftap.status === 'Rejected') {
          finalPayload.aftap = {
            barcode: this.form.aftap.barcode,
            blood_bag_number: this.form.aftap.blood_bag_number,
            antiseptic_wipe: this.form.aftap.antiseptic_wipe,
            venipuncture_start_time: this.form.aftap.venipuncture_start_time,
            venipuncture_end_time: this.form.aftap.venipuncture_end_time,
            collection_reaction: this.form.aftap.collection_reaction,
            uptake_reaction: this.form.aftap.uptake_reaction,
            side_effect: this.form.aftap.side_effect,
            reason_rejected: this.form.aftap.reason_rejected,
            status: this.form.aftap.status,
          }

          return finalPayload
        }

        finalPayload.aftap = { ...this.form.aftap }

        return finalPayload
      }

      try {
        const payloadToSubmit = getValidPayload()

        if (this.selectedMU && this.form.reservation) {
          payload.reservation.hospital_id = this.form.reservation.hospital_id ?? null
          payload.reservation.blood_van_id = this.form.reservation.blood_van_id ?? null
        }

        const response = await manageExams.add(payloadToSubmit)

        console.log(response.data.data)
      } catch (e) {
        console.error(e)
      }
    },
    clearForm(typeForm) {
      switch (typeForm) {
        case 'admin':
          this.form.reservation = {
            blood_van: '',
            date: '',
            validation: '',
            prev_history: '-',
            donor_times: null,
            donor_type: '-',
            last_donor_date: '',
            os: '-',
            rs: '-',
            status: '',
          }
          break
        case 'dokter':
          this.form.doctor = {
            blood_pressure: '',
            weight: 0,
            height: 0,
            general_condition: '',
            pulse: '',
            temperature: 0,
            medical_history: '',
            collection_method: '',
            bag: '',
            cc: '',
            status: '',
            reason_rejected: '',
          }
          break
        case 'hb':
          this.form.hb = {
            hb_meter: 0,
            blood_type: '',
            reason_rejected: '',
            status: '',
          }
          break
        case 'aftap':
          this.form.aftap = {
            barcode: '',
            blood_bag_number: '',
            antiseptic_wipe: '',
            venipuncture_start_time: '',
            venipuncture_end_time: '',
            collection_reaction: '',
            uptake_reaction: '',
            uptake_reaction_detail: '',
            side_reaction: '',
            side_reaction_detail: '',
            reason_rejected: '',
            status: '',
          }
          break
        default:
          // be careful if you're using this function without passing params
          this.form = {}
          break
      }
    },
    handleInputMU(selectedValue) {
      this.selectedMU = selectedValue

      this.form.reservation = {
        ...this.form.reservation,
        hospital_id: selectedValue.type === 'hospital' ? selectedValue.hospital_id : null,
        blood_van_id: selectedValue.type === 'blood_van' ? selectedValue.id : null,
      }
    },
    updateForm(newValue) {
      this.form = newValue
    },
    handleStatusChange() {
      const approvedStatusesCount = ['hb', 'aftap', 'reservation', 'doctor'].reduce(
        (count, section) => (this.form[section].status === 'Done' ? count + 1 : count), 0,
      )

      this.form.step_details.step = Math.max(this.form.step_details.step, Math.min(approvedStatusesCount, 4))

      const isRejected = ['hb', 'aftap', 'reservation', 'doctor'].some(
        section => this.form[section].status === 'Rejected',
      )

      this.form.step_details.is_rejected = isRejected ? 1 : 0
    },
  },
}
</script>
