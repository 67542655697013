<template>
  <b-card
    class="mb-3"
  >
    <div class="d-flex justify-content-between">
      <h2>Dokter</h2>
      <b-form-radio-group
        v-model="form.doctor.status"
        :options="[
          { text: 'Diterima', value: 'Done' },
          { text: 'Ditolak', value: 'Rejected' },
        ]"
      />
    </div>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Tekanan Darah"
          label-for="blood_pressure"
        >
          <b-form-input
            id="blood_pressure"
            v-model="form.doctor.blood_pressure"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Berat (kg)"
          label-for="weight"
        >
          <b-form-input
            id="weight"
            v-model="form.doctor.weight"
            type="number"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Tinggi (cm)"
          label-for="height"
        >
          <b-form-input
            id="height"
            v-model="form.doctor.height"
            type="number"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Suhu"
          label-for="temperature"
        >
          <b-form-input
            id="temperature"
            v-model="form.doctor.temperature"
            type="number"
            step="0.1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Kondisi Umum"
          label-for="general_condition"
        >
          <b-form-input
            id="general_condition"
            v-model="form.doctor.general_condition"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Jenis Kantong Darah"
          label-for="blood_bag_type"
        >
          <b-form-select
            id="blood_bag_type"
            v-model="form.doctor.bag"
            :options="[
              { text: 'Pilih Jenis Kantong Darah', value: '' },
              { text: 'DB', value: 'DB' },
              { text: 'TR', value: 'TR' },
            ]"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <b-form-group
          label="Diambil Sebanyak"
          label-for="cc"
        >
          <b-form-select
            id="cc"
            v-model="form.doctor.cc"
            :options="[
              { text: 'Pilih Diambil Sebanyak', value: '' },
              { text: '350 cc', value: '350' },
              { text: '450 cc', value: '450' },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Metode Pengambilan Darah"
          label-for="collection_method"
        >
          <b-form-select
            id="collection_method"
            v-model="form.doctor.collection_method"
            :options="[
              { text: '', value: 'Pilih Metode Pengambilan Darah' },
              { text: 'Biasa', value: 'Biasa' },
              { text: 'Aferesis', value: 'Aferesis' },
              { text: 'Autologus', value: 'Autologus' },
            ]"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          label="Alasan Penolakan"
          label-for="reason_rejected"
        >
          <b-form-input
            id="reason_rejected"
            :value="doctorRejectionReason"
            readonly
            :state="doctorApproved ? null : false"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button
          style="cursor: pointer;"
          variant="text"
          class="h5 text-primary hp-hover-bg-primary-4 hp-hover-bg-dark-primary"
          @click="emitClearForm"
        >Reset</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BFormRadioGroup,
  BCard, BCol, BFormGroup, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'

export default {
  name: 'FormDokter',
  components: {
    BCard, BRow, BFormInput, BFormSelect, BFormGroup, BCol, BFormRadioGroup,
  },
  props: ['doctorRejectionReason', 'doctorApproved', 'form', 'form-type'],
  methods: {
    emitClearForm() {
      this.$emit('clear-form', this.formType)
    },
  },
}
</script>

<style scoped>

</style>
